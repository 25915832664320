import React, { useState } from "react";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChartX from "../../../Chart";

import { useHistory } from "react-router-dom";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <FaAngleRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <FaAngleLeft />
    </div>
  );
}

const MultipleSlider = ({ charts = [], category = {} }) => {
  const history = useHistory();

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="multiple__slider constant__margin-top multiple__slider-chart">
      <Slider {...settings}>
        {charts.map((chart) => {
          const parsedData = JSON.parse(chart.data);

          const charts = parsedData.charts;
          const settings = parsedData.settings;

          const defaultChart =
            charts &&
            charts[settings.defaultChart] &&
            charts[settings.defaultChart].yearData &&
            charts[settings.defaultChart].yearData[settings.defaultYear];

          if (!defaultChart) return;

          return (
            <div className="chart-box">
              <ChartX
                chartData={defaultChart}
                dataSet={defaultChart.data}
                chartType={defaultChart.type}
                settings={defaultChart.settings}
                showViewTable={false}
              />
              <span
                className="vis-card-title"
                onClick={() => {
                  history.push(`/charts/${category.id}/data/${chart.id}`);
                }}
              >
                {chart.title} ({defaultChart.year})
              </span>
            </div>
          );
        })}
      </Slider>
      <span
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          cursor: "pointer",
        }}
        onClick={() => {
          history.push(`/charts/${category.id}`);
        }}
      >
        View More
      </span>
    </div>
  );
};

export default MultipleSlider;
