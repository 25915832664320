import React from "react";

const Overlay = ({ show, close }) => {
  return (
    <>
      {show && (
        <div
          className={`overlay-2 ${show && "overlay_active"}`}
          onClick={close}
        />
      )}
    </>
  );
};

export default Overlay;
