import React, { useState, useEffect, useRef } from "react";

function Dropdown({ label, options, selected, onChangeSelected }) {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    function onBodyClick(e) {
      if (!ref) return;
      if (!(ref && ref.current && ref.current.contains(e.target))) {
        setOpen(false);
      }
    }
    document.querySelector("body").addEventListener("click", onBodyClick, {
      capture: true,
    });

    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, []);
  const renderedOptions =
    !!options.length &&
    options.map((option) => {
      return (
        <div
          key={option.value}
          className="item options"
          onClick={() => onChangeSelected(option)}
        >
          {option.label}
        </div>
      );
    });
  return (
    <div className="ui" ref={ref}>
      <div className="field form__group">
        <label htmlFor="text-1" className="label__text">
          <span>{label}</span>
          <span>:</span>
        </label>
        <div
          onClick={() => setOpen(!open)}
          className={`ui selection dropdown input ${
            open ? `visible active` : ""
          }`}
        >
          <div className="dropdown icon"></div>
          <div className="text" id="text-1">
            {selected && selected.label}
          </div>
          <div className={`menu ${open ? "visible transition" : ""}`}>
            {renderedOptions}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
