export default {
  me: "/api/v1/auth/profile/",
  register: "/api/v1/register/",

  login: "/api/v1/auth/token/login/",
  logout: "",
  verifyMessage: "",

  changePassword: "/api/v1/password/change",

  document: "/api/v1/documents/",
  documentCategory: "/api/v1/document-categories/",
  documentGroup: "/api/v1/document-groups/",
  homeImage: "/api/v1/dashboard/",
  monthlyHighlight: "/api/v1/monthly-highlights/",
  newsletter: "/api/v1/newsletters/",
  notice: "/api/v1/notices/",
  partner: "/api/v1/partners/",
  projectUpdate: "/api/v1/project-updates/",
  permission: "/api/v1/auth/permissions/",
  quickLink: "/api/v1/uploads/",
  quickLinkCategory: "api/v1/upload-categories/",
  user: "/api/v1/users/",
  websiteInformation: "/api/v1/website-info/",
  metrics: "/api/v1/metrics/",
  theme: "/api/v1/themes/",
  outcome: "/api/v1/outcomes/",
  indicator: "/api/v1/indicators/",

  metricsCategory: "/api/v1/metrics-categories/",

  forgotPassword: "/api/v1/password/reset",
  accessGroup: "/api/v1/access-groups/",
  resetPassword: "/api/v1/password/reset/confirm",

  metricsGroupType: "/api/v1/metrics-group-types/",
  metricsGroup: "/api/v1/metrics-groups/",
};
