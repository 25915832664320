import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { verifyUser, resetNotification } from "../../store/app/actions";

import Notification from "../../components/Notification/index";

const VerifyMessage = ({
  match: {
    params: { token, uid },
  },
  verifyUser,
  notification,
  resetNotification,
}) => {
  const [success, setSuccess] = useState(false);

  const verify = async () => {
    (await verifyUser({ token, uid })) && setSuccess(true);
  };

  useEffect(() => {
    verify();
  }, [token, uid]);

  return (
    <div style={{ backgroundColor: "#f6f6f5", minHeight: "100vh" }}>
      <div className="red__border"></div>
      {success && (
        <div
          className="message__box"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="image">
            <img
              src={require("../../assets/images/logo-tr.png").default}
              alt=""
              style={{ width: "200px" }}
            />
          </div>
          <h1>Account Verification</h1>
          You have been succesfully verfied...
          <br />
          <br />
          <button
            className="btn-modal"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Go to login
          </button>
        </div>
      )}
      {!!(notification && notification.length) && (
        <Notification
          data={notification[0]}
          resetNotification={resetNotification}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ appState: { notification } }) => ({
  notification,
});

export default connect(mapStateToProps, {
  verifyUser,
  resetNotification,
})(VerifyMessage);
