import axios from "axios";

import { setIsLoading } from "../store/app/actions";

import { configuration } from "../configuration/env";

export const AuthToken = `Token ${sessionStorage.getItem("token")}`;

const network = ({ requireToken = true, dispatch }) => {
  const axiosConfig = {
    baseURL: configuration.api.url || "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    timeout: 60000,
  };

  if (requireToken) {
    axiosConfig.headers["Authorization"] = AuthToken;
  }

  const clientRequest = axios.create(axiosConfig);

  clientRequest.interceptors.request.use(
    (conf) => {
      dispatch(setIsLoading(true));

      return conf;
    },
    (error) => Promise.reject(error)
  );

  clientRequest.interceptors.response.use(
    (conf) => {
      if (conf && conf.status && (conf.status === 200 || conf.status === 201)) {
        dispatch(setIsLoading(false));
      }

      return conf;
    },
    (error) => Promise.reject(error)
  );

  return clientRequest;
};

export default network;
