import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { registerUser, resetNotification } from "../../store/app/actions";
import { fetchAccessGroups } from "../../store/accessGroup/actions";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Overlay from "../Overlay/Overlay";
import Dropdown from "../Dropdown/Dropdown";
import Modal from "../Model/Model";
import { Formik, Form } from "formik";
import Notification from "../../components/Notification/index";

const LoginForm = ({
  registerUser,
  fetchAccessGroups,
  accessGroups = [],
  resetNotification,
  notification,
  isLoading,
}) => {
  const [getModal, setModal] = useState(false);

  useEffect(() => {
    fetchAccessGroups({});
  }, []);

  function closeOverlay() {
    setModal(false);
    window.location.href = "/";
  }

  const initialValues = {
    username: "",
    password: "",
    organization: "",
    group: "",
    email: "",
    full_name: "",
    password2: "",
  };

  const validationSchema = Yup.object({});

  const onSubmit = async (values, { resetForm }) => {
    if (
      await registerUser({ values: { ...values, group: values.group.value } })
    ) {
      resetForm();
      setModal(true);
    }
  };
  const ModalHeading = (
    <div className="modal-sahayatra__success">
      <svg viewBox="0 0 166 166" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M83 0C66.5842 0 50.537 4.86786 36.8877 13.988C23.2384 23.1082 12.6001 36.071 6.31803 51.2373C0.0359626 66.4035 -1.60771 83.092 1.59486 99.1925C4.79743 115.293 12.7024 130.082 24.3102 141.69C35.9179 153.298 50.7071 161.203 66.8075 164.405C82.9079 167.608 99.5965 165.964 114.763 159.682C129.929 153.4 142.892 142.762 152.012 129.112C161.132 115.463 166 99.4158 166 83C166 60.987 157.255 39.8756 141.69 24.3101C126.124 8.74462 105.013 0 83 0ZM137.209 55.1431L69.0456 123.255L28.7907 83C27.4148 81.6242 26.6419 79.7582 26.6419 77.8125C26.6419 75.8668 27.4148 74.0008 28.7907 72.625C30.1665 71.2492 32.0325 70.4762 33.9782 70.4762C35.9238 70.4762 37.7898 71.2492 39.1657 72.625L69.1494 102.609L126.938 44.8719C127.619 44.1906 128.428 43.6502 129.318 43.2816C130.208 42.9129 131.162 42.7231 132.126 42.7231C133.089 42.7231 134.043 42.9129 134.933 43.2816C135.823 43.6502 136.632 44.1906 137.313 44.8719C137.994 45.5531 138.535 46.3618 138.903 47.2519C139.272 48.142 139.462 49.096 139.462 50.0594C139.462 51.0228 139.272 51.9767 138.903 52.8668C138.535 53.7569 137.994 54.5656 137.313 55.2469L137.209 55.1431Z"
          fill="#0FA958"
        />
      </svg>
    </div>
  );

  return (
    <>
      <Overlay show={getModal} close={closeOverlay} />
      <Modal
        image={ModalHeading}
        show={getModal}
        close={closeOverlay}
        buttonAction={closeOverlay}
        buttonName="OK"
        heading="Your request has been sent"
        body=" Verification link will be sent to your mail to complete registration
        process."
      />
      <div className="red__border">
        <div className="form__container constant__padding register__container">
          <div className="image__content">
            <div className="welcome__text heading__primary">Welcome</div>
            <div className="image">
              <img
                src={require("../../assets/images/saveTheChildren.png").default}
                alt=""
              />
            </div>
          </div>
          <div className="form__content-sign">
            <h2 className="heading__primary">Request Access</h2>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              validateOnMount={false}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize
            >
              {({ values, errors, handleChange, setFieldValue }) => (
                <Form className="form">
                  <div className="control-group">
                    <div className="control-group">
                      <div className="form--control">
                        <div className="form__group">
                          <label htmlFor="userName">
                            <span>User Name</span>
                            <span>:</span>
                          </label>
                          <input
                            type="text"
                            id="userName"
                            name="username"
                            onChange={handleChange}
                            value={values.username}
                          />
                        </div>
                        {errors && errors.username && <p>{errors.username}</p>}
                      </div>
                      {/* password */}
                      <div className="form--control">
                        <div className="form__group">
                          <label htmlFor="password">
                            <span>Password</span>
                            <span>:</span>
                          </label>
                          <input
                            type="password"
                            id="password"
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                          />
                        </div>
                        {errors && errors.password && <p>{errors.password}</p>}
                      </div>
                    </div>
                    <div className="form--control">
                      <div className="form__group">
                        <label htmlFor="re-password">
                          <span>Re-Password</span>
                          <span>:</span>
                        </label>
                        <input
                          type="password"
                          id="re-password"
                          name="password2"
                          onChange={handleChange}
                          value={values.password2}
                        />
                      </div>
                      {errors && errors.password2 && <p>{errors.password2}</p>}
                    </div>
                    {/* name */}
                    <div className="form--control">
                      <div className="form__group">
                        <label htmlFor="Name">
                          <span>Name</span>
                          <span>:</span>
                        </label>
                        <input
                          type="text"
                          name="full_name"
                          onChange={handleChange}
                          value={values.full_name}
                        />
                      </div>
                      {errors && errors.full_name && <p>{errors.full_name}</p>}
                    </div>
                    {/* organization */}
                    <div className="form--control">
                      <div className="form__group">
                        <label htmlFor="organization">
                          <span>Organization</span>
                          <span>:</span>
                        </label>
                        <input
                          type="text"
                          name="organization"
                          id="organization"
                          onChange={handleChange}
                          value={values.organization}
                        />
                      </div>
                      {errors && errors.organization && (
                        <p>{errors.organization}</p>
                      )}
                    </div>
                    <div className="form--control">
                      <div className="form__group">
                        <label htmlFor="name">
                          <span>E-Mail Address</span>
                          <span>:</span>
                        </label>
                        <input
                          type="email"
                          id="name"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                        />
                      </div>
                      {errors && errors.email && <p>{errors.email}</p>}
                    </div>

                    {/* dropdown */}
                    <div className="form--control">
                      <Dropdown
                        label="Access Types"
                        selected={values.group}
                        onChangeSelected={(data) => {
                          setFieldValue("group", data);
                        }}
                        options={accessGroups.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                      />
                    </div>
                  </div>
                  {errors && errors.username && <p>{errors.username}</p>}

                  <div className="form-actions">
                    <button
                      className="btn btn--transparent"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Submitting" : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="links__cr">
              <Link to="/forgot-password">Forgot Password?</Link>
              <p>
                Have an account? <Link to="/login">Log In</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {!!(notification && notification.length) && (
        <Notification
          data={notification[0]}
          resetNotification={resetNotification}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  appState: { notification, isLoading },
  accessGroupState: { accessGroups },
}) => ({
  notification,
  isLoading,
  accessGroups,
});
export default connect(mapStateToProps, {
  registerUser,
  fetchAccessGroups,
  resetNotification,
})(LoginForm);
