import { SET_METRICS_GROUP_TYPES_DATA } from "./action-types";

import { setIsLoading, setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";

export const setMetricsGroupTypesData = (payload) => ({
  type: SET_METRICS_GROUP_TYPES_DATA,
  payload,
});

export const fetchMetricsGroupTypes =
  ({}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({ dispatch }).get(
        url.metricsGroupType
      );
      if (status === 200 || status > 200) {
        if (data && data) {
          dispatch(setMetricsGroupTypesData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
