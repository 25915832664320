import React from "react";
import { connect } from "react-redux";
import { loginUser, resetNotification } from "../../store/app/actions";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import Notification from "../../components/Notification/index";

const LoginForm = ({
  loginUser,
  notification,
  isLoading,
  resetNotification,
}) => {
  const initialValues = { email: "", password: "" };

  const validationSchema = Yup.object({
    email: Yup.string().required().label("Email"),
    password: Yup.string().required().label("Password"),
  });

  const onSubmit = async (values) => await loginUser({ values });

  return (
    <>
      <div className="red__border">
        <div className="form__container constant__padding ">
          <div className="image__content">
            <div className="welcome__text heading__primary">Welcome</div>
            <div className="image">
              <img
                src={require("../../assets/images/saveTheChildren.png").default}
                alt=""
              />
            </div>
          </div>
          <div className="form__content-sign">
            <h2 className="heading__primary">Login Page</h2>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              validateOnMount={false}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize
            >
              {({ values, errors, handleChange }) => (
                <Form className="form">
                  <div className="control-group">
                    <div className="form--control">
                      <div className="form__group">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="far"
                          data-icon="user"
                          class="svg-inline--fa fa-user fa-w-14 "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          color="black"
                        >
                          <path
                            fill="#f8f8f8"
                            d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"
                          ></path>
                        </svg>
                        <label htmlFor="name">Email</label>
                      </div>
                      <input
                        type="text"
                        id="name"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors && errors.email && <>{errors.email}</>}
                    </div>
                    {/* password */}
                    <div className="form--control">
                      <div className="form__group">
                        <svg
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 14.3333H12.3333M17 11.6667V10.3333C17 9.97971 16.8595 9.64057 16.6095 9.39052C16.3594 9.14048 16.0203 9 15.6667 9H2.33333C1.97971 9 1.64057 9.14048 1.39052 9.39052C1.14048 9.64057 1 9.97971 1 10.3333V18.3333C1 18.687 1.14048 19.0261 1.39052 19.2761C1.64057 19.5262 1.97971 19.6667 2.33333 19.6667H15.6667C16.0203 19.6667 16.3594 19.5262 16.6095 19.2761C16.8595 19.0261 17 18.687 17 18.3333V17V11.6667ZM17 11.6667H11.6667C10.9594 11.6667 10.2811 11.9476 9.78105 12.4477C9.28095 12.9478 9 13.6261 9 14.3333C9 15.0406 9.28095 15.7189 9.78105 16.219C10.2811 16.719 10.9594 17 11.6667 17H17V11.6667ZM17 11.6667C17.7072 11.6667 18.3855 11.9476 18.8856 12.4477C19.3857 12.9478 19.6667 13.6261 19.6667 14.3333C19.6667 15.0406 19.3857 15.7189 18.8856 16.219C18.3855 16.719 17.7072 17 17 17V11.6667ZM5 9V5C5 3.93913 5.42143 2.92172 6.17157 2.17157C6.92172 1.42143 7.93913 1 9 1C10.0609 1 11.0783 1.42143 11.8284 2.17157C12.5786 2.92172 13 3.93913 13 5V9H5ZM16.3333 14.3333H17.6667H16.3333ZM13.6667 14.3333H15H13.6667Z"
                            stroke="#F8F8F8"
                          />
                        </svg>

                        <label htmlFor="name">Password</label>
                      </div>
                      <input
                        type="password"
                        id="name"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      {errors && errors.password && <>{errors.password}</>}
                    </div>
                  </div>

                  <div className="form-actions">
                    <button
                      className="btn btn--transparent"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Submitting" : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="links__cr">
              <Link to="/forgot-password">Forgot Password?</Link>
              <p>
                Don't have an account? <Link to="/register">Create</Link>
              </p>
            </div>
          </div>
        </div>
      </div>{" "}
      {!!(notification && notification.length) && (
        <Notification
          data={notification[0]}
          resetNotification={resetNotification}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ appState: { notification, isLoading } }) => ({
  notification,
  isLoading,
});
export default connect(mapStateToProps, {
  loginUser,
  resetNotification,
})(LoginForm);
