import url from "../../constants/url";

import network from "../../utils/network";

import jsCookie from "js-cookie";

import {
  SET_IS_LOADING,
  SET_ME,
  SET_IS_PERMISSION_LOADING,
  SET_NOTIFICATION,
  SET_ERROR_NOTIFICATION,
  RESET_NOTIFICATION,
} from "./action-types";

export const setIsLoading = (payload) => ({ type: SET_IS_LOADING, payload });

export const setErrorMessage = (err) => {
  let obs = err && err.response && err.response.data;

  let message;

  if (err.response.data.results) {
    obs = err.response.data.results;
  }

  if (obs) {
    const errorKeyArray = Object.keys(obs);

    const errorMessageArray = Object.values(obs);

    if (errorMessageArray.length) {
      message = errorMessageArray[0];
    }
  }

  return {
    type: SET_ERROR_NOTIFICATION,
    payload: {
      name: (err && err.response && err.response.name) || "Error",
      message: message || "Error has occurred",
      level: "error",
    },
  };
};
export const setMe = (payload) => ({ type: SET_ME, payload });

export const setIsPermissionLoading = (payload) => ({
  type: SET_IS_PERMISSION_LOADING,
  payload,
});

export const setNotification = (payload) => ({
  type: SET_NOTIFICATION,
  payload,
});
export const setErrorNotification = (payload) => ({
  type: SET_ERROR_NOTIFICATION,
  payload,
});
export const resetNotification = () => ({
  type: RESET_NOTIFICATION,
});

export const logout = () => {
  jsCookie.remove("token");
  window.localStorage.setItem("logout", Date.now().toString());
  window.location.href = "/login";
};

export const loginUser =
  ({ values }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(false));

      const { data, status } = await network({
        requireToken: false,
        dispatch,
      }).post(url.login, values);

      if (status === 200 && data) {
        data.results &&
          sessionStorage.setItem("token", data.results.auth_token);
        window.location.href = "/";
        dispatch(
          setNotification({
            name: "Login",
            message: data.message,
            level: "success",
          })
        );
        dispatch(setMe(data));
        dispatch(setIsLoading(false));

        return;
      }
    } catch (error) {
      dispatch(setErrorMessage(error));
    }
  };

export const registerUser =
  ({ values }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(false));

      const { data, status } = await network({
        requireToken: false,
        dispatch,
      }).post(url.register, values);

      if (status === 201 && data) {
        dispatch(setIsLoading(false));

        return true;
      }
    } catch (error) {
      dispatch(setErrorMessage(error));
    }
  };

export const fetchMe = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(setIsPermissionLoading(true));

    const { data, status } = await network({ dispatch }).get(url.me);

    if (status === 200 || status > 200) {
      if (data) {
        dispatch(setMe(data));
        dispatch(setIsLoading(false));
        dispatch(setIsPermissionLoading(false));
      }
      return true;
    }
    return false;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
    dispatch(setIsPermissionLoading(false));
    return false;
  }
};

export const forgotPassword =
  ({ values }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const { status, data } = await network({
        dispatch,
        requireToken: false,
      }).post(url.forgotPassword, {
        email: String(values.email).toLowerCase(),
      });

      if (status === 200 || status === 201) {
        dispatch(
          setNotification({
            name: "Login",
            message: "Please check you email.",
            level: "success",
          })
        );
        return true;
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const resetPassword =
  ({ values, token, uid }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const { status, data } = await network({
        dispatch,
        requireToken: false,
      }).post(url.resetPassword, {
        ...values,
        token,
        uid,
      });

      if (status === 200 || status === 201) {
        dispatch(
          setNotification({
            name: "Login",
            message: "Password Changed Successfully.",
            level: "success",
          })
        );
        return true;
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const changePassword =
  ({ current_password, new_password }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const { status, data } = await network({
        dispatch,
      }).post(url.changePassword, {
        current_password,
        new_password,
      });

      if (status === 200 || status === 201) {
        return true;
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const verifyUser =
  ({ token, uid }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const { status, data } = await network({
        dispatch,
      }).get(`http://mangosoftsolution.com:8010/activate/${uid}/${token}/`);

      if (status === 200 || status === 201) {
        dispatch(
          setNotification({
            name: "Login",
            message: "User verified.",
            level: "success",
          })
        );
        return true;
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
