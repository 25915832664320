import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import ChartX from "../Chart";
import { Bar, Line } from "react-chartjs-2";

import { fetchMetrics } from "../../store/metrics/actions";
import { Row, Col } from "react-bootstrap";

const ChartPage = ({
  match: {
    params: { chartId },
  },
  fetchMetrics,
  metrics,
  isLoading,
}) => {
  const [metricsData, setMetricsData] = useState();

  useEffect(() => {
    chartId && fetchMetrics({ id: chartId });
  }, [chartId]);

  useEffect(() => {
    metrics ? setMetricsData(metrics) : setMetricsData(undefined);
  }, [metrics]);

  if (isLoading) {
    return (
      <div className="constant__padding min_height">
        <div className="loader-sm" />
      </div>
    );
  }

  if (!metrics || !metricsData) return <></>;

  const parsedData = JSON.parse(metricsData.data);

  const charts = parsedData.charts;
  const settings = parsedData.settings;

  let chartDump = {};

  Object.entries(charts).map(
    (chart) =>
      chart[1].yearData &&
      Object.keys(chart[1].yearData).map((year) => {
        chartDump[chart[0]] = {
          ...(chartDump[chart[0]] || {}),
          [`${year}`]: "",
        };
      })
  );

  return (
    <div className="charts__page constant__padding">
      <div className="section__heading constant__margin-top constant__margin-bottom">
        {metricsData.title}
      </div>
      <Row>
        {Object.keys(chartDump).map((chartIndex) => {
          return (
            <Col lg={12} className="mb-5">
              <Row>
                <Col lg={12} className="mb-2">
                  {charts[chartIndex].title}
                </Col>
                <Col lg={12}>
                  <Row>
                    {Object.keys(chartDump[chartIndex]).map((year) => {
                      const chartData =
                        charts &&
                        charts[chartIndex] &&
                        charts[chartIndex].yearData &&
                        charts[chartIndex].yearData[year];

                      return (
                        <Col lg={4}>
                          <p>{year}</p>
                          <ChartX
                            ChartElement={Bar}
                            chartData={chartData}
                            dataSet={chartData.data}
                            chartType={chartData.type}
                            settings={chartData.settings}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <br />
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

const mapStateToProps = ({ metricsState: { metrics, isLoading } }) => ({
  metrics,
  isLoading,
});
export default connect(mapStateToProps, {
  fetchMetrics,
})(ChartPage);
