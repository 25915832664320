import { SET_IS_LOADING, SET_NEWSLETTERS_DATA } from "./action-types";

import { setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";

export const setIsLoading = (payload) => ({ type: SET_IS_LOADING, payload });

export const setNewslettersData = (payload) => ({
  type: SET_NEWSLETTERS_DATA,
  payload,
});

export const fetchNewsletters =
  ({}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({ dispatch }).get(url.newsletter);
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setNewslettersData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const updateViewCount =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({ dispatch }).patch(
        `${url.newsletter}${id}/view-count/`
      );
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const updateDownloadCount =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({ dispatch }).patch(
        `${url.newsletter}${id}/download-count/`
      );
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
