import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { EditorState, convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";
import { convertToHTML } from "draft-convert";
import { fetchWebsiteInformations } from "../../store/websiteInformation/actions";

const Component = ({
  fetchWebsiteInformations,
  websiteInformations = [],
  isLoading,
}) => {
  useEffect(() => {
    fetchWebsiteInformations({});
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    const about = websiteInformations.find((item) => item.slug === "about");

    if (about && about.description) {
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(about.description))
        )
      );
    }
  }, [websiteInformations]);

  useEffect(() => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }, [editorState]);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  if (isLoading) {
    return (
      <div className="constant__padding min_height">
        <div className="loader-sm" />
      </div>
    );
  }

  return (
    <div className="constant__padding min_height">
      <div
        className="preview-about"
        dangerouslySetInnerHTML={createMarkup(convertedContent)}
      ></div>
    </div>
  );
};

const mapStateToProps = ({
  websiteInformationState: { isLoading, websiteInformations },
}) => ({
  websiteInformations,
  isLoading,
});

export default connect(mapStateToProps, {
  fetchWebsiteInformations,
})(Component);
