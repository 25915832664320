import { SET_IS_LOADING, SET_INDICATORS_DATA } from "./action-types";

export const initialState = { isLoading: false, indicators: undefined };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_INDICATORS_DATA:
      return { ...state, indicators: action.payload };

    default:
      return state;
  }
};
