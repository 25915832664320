import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FakeLinks from "../../DummyData/FakeLinks";
import { Link } from "react-router-dom";
import { fetchQuickLinksByCategory } from "../../store/quickLink/actions";

const QuickLinks = ({
  match,
  quickLinks,
  isLoading,
  fetchQuickLinksByCategory,
}) => {
  const {
    params: { id },
  } = match;

  const [links, setLinks] = useState([]);

  useEffect(() => {
    id && fetchQuickLinksByCategory({ id });
  }, [id]);

  useEffect(() => {
    quickLinks && quickLinks.uploads && quickLinks.uploads.length
      ? setLinks(quickLinks.uploads)
      : setLinks([]);
  }, [quickLinks]);

  if (isLoading) {
    return (
      <div className="constant__padding min_height">
        <div className="loader-sm" />
      </div>
    );
  }

  return (
    <div className="constant__padding min_height links__page">
      {quickLinks && <p>{quickLinks.title}</p>}
      {links.map((link, id) => {
        return (
          <Link
            to={{
              pathname: link.url,
            }}
            target="_blank"
            key={link.id}
          >
            <span>{`${id + 1}. ${link.title} `}</span>

            <span>
              <i class="fas fa-external-link-alt"></i>
            </span>
          </Link>
        );
      })}
    </div>
  );
};

const mapStateToProps = ({ quickLinkState: { quickLinks, isLoading } }) => ({
  quickLinks,
  isLoading,
});
export default connect(mapStateToProps, {
  fetchQuickLinksByCategory,
})(QuickLinks);
