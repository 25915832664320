import React, { useEffect, useState } from "react";
import List from "../../List/List";
import Search from "../../Search/Search";

const TabsList = (props) => {
  return <ul className="tabs__list">{props.children}</ul>;
};

const TabsItem = ({ children, className, handleClick }) => {
  return (
    <li className={`tabs__item ${className}`} onClick={handleClick}>
      {children}
    </li>
  );
};

const TabContent = (props) => {
  return (
    <div
      className={`tabs__content ${
        props.activeTab == props.tabId ? "active" : ""
      }`}
    >
      {props.children}
    </div>
  );
};

const Tabs = ({ onView, categoryId, documents = {}, documentGroups = [] }) => {
  const [selectedDocumentData, setSelectedDocumentData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    documents.documents && setDocumentData(documents.documents);
  }, [documents]);

  useEffect(() => {
    categoryId &&
      setGroupData(
        documentGroups.filter(
          (item) => item.document_category === Number(categoryId)
        )
      );
  }, [categoryId, documentGroups]);

  useEffect(() => {
    groupData && groupData.length && setSelectedTab(groupData[0].id);
  }, [groupData, categoryId]);

  useEffect(() => {
    setSelectedDocumentData(
      groupData
        ?.find((grp) => grp.id === selectedTab)
        ?.documents?.filter((item) => item.is_active)
    );
  }, [selectedTab, groupData]);


  return (
    <div>
      <div className="tabs__style">
        <div className="tabs__style">
          <TabsList>
            {groupData.map((item, index) => (
              <TabsItem
                handleClick={() => setSelectedTab(item.id)}
                className={selectedTab === item.id ? "active" : ""}
                key={item.id}
              >
                {item.title}
              </TabsItem>
            ))}
          </TabsList>
          <div className="document__head-info">
            <p>Total Documents : {selectedDocumentData?.length}</p>
            <Search></Search>
          </div>
          <div className="tabs__content-wrapper">
            <List data={selectedDocumentData || []} onView={onView} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
