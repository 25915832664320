import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import ChartX from "../Chart";
import { Bar, Line } from "react-chartjs-2";
import Dropdown from "../Dropdown/Dropdown";

import { fetchMetricss } from "../../store/metrics/actions";
import { fetchMetricsCategorys } from "../../store/metricsCategory/actions";
import { fetchMetricsGroups } from "../../store/metricsGroup/actions";
import { fetchMetricsGroupTypes } from "../../store/metricsGroupType/actions";

import { useHistory } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

const ChartPage = ({
  match: {
    params: { categoryId },
  },
  fetchMetricss,
  fetchMetricsCategorys,
  fetchMetricsGroups,
  fetchMetricsGroupTypes,
  metricss = [],
  metricsCategorys = [],
  metricsGroupTypes = [],
  metricsGroups = [],
  isLoading,
}) => {
  const history = useHistory();

  const [category, setCategory] = useState();
  const [metricsData, setMetricsData] = useState([]);

  const [meta, setMeta] = useState({});

  const [groupData, setGroupData] = useState([]);

  useEffect(() => {
    fetchMetricss({});
    fetchMetricsCategorys({});
    fetchMetricsGroupTypes({});
    fetchMetricsGroups({});
  }, []);

  useEffect(() => {
    if (categoryId) {
      setCategory(
        metricsCategorys.find((item) => item.id === Number(categoryId))
      );
    }
  }, [metricsCategorys, categoryId]);

  useEffect(() => {
    if (categoryId) {
      setMetricsData(
        metricss.filter((item) => item.metrics_category === Number(categoryId))
      );
    }
  }, [metricss, categoryId]);

  let checker = (arr, target) => target.every((v) => arr.includes(v));

  useEffect(() => {
    if (categoryId) {
      if (Object.keys(meta).length) {
        setMetricsData(
          metricss.filter((item) => {
            const target = Object.values(meta).map((item) =>
              Number(item.value)
            );

            return (
              item.metrics_category === Number(categoryId) &&
              checker(item.metrics_group_types, target)
            );
          })
        );
      } else {
        setMetricsData(
          metricss.filter((item) => {
            const target = Object.values(meta).map((item) =>
              Number(item.value)
            );

            return item.metrics_category === Number(categoryId);
          })
        );
      }
    }
  }, [metricss, categoryId, meta]);

  useEffect(() => {
    categoryId &&
      setGroupData(
        metricsGroups.filter(
          (item) => item.metrics_category === Number(categoryId)
        )
      );
  }, [categoryId, metricsGroups]);

  if (isLoading) {
    return (
      <div className="constant__padding min_height">
        <div className="loader-sm" />
      </div>
    );
  }

  if (!category) return <></>;

  return (
    <div className="charts__page constant__padding min_height">
      <p className="section__heading constant__margin-top"> {category.title}</p>
      <Row className="mb-5 mt-5">
        {groupData.map((group) => {
          return (
            <Col>
              <div style={{ position: "relative" }}>
                {meta[group.id] && (
                  <div
                    onClick={() =>
                      setMeta((prev) => {
                        const temp = JSON.parse(JSON.stringify(prev));
                        delete temp[group.id];
                        return temp;
                      })
                    }
                    style={{
                      position: "absolute",
                      top: "-5px",
                      right: "-5px",
                      zIndex: 1000,
                      color: "white",
                      background: "red",
                      borderRadius: "100%",
                      width: "2.5rem",
                      height: "2.5rem",
                      padding: ".5rem",
                      cursor: "pointer",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    X
                  </div>
                )}

                <Dropdown
                  label={`Choose ${group.title}`}
                  selected={meta[group.id]}
                  onChangeSelected={(data) =>
                    setMeta((prev) => ({
                      ...prev,
                      [`${group.id}`]: data,
                    }))
                  }
                  options={metricsGroupTypes
                    .filter((item) => item.metrics_group === group.id)
                    .map((item) => ({
                      value: item.id,
                      label: item.title,
                    }))}
                />
              </div>
            </Col>
          );
        })}
      </Row>
      <Row>
        {metricsData.map((chart) => {
          const parsedData = JSON.parse(chart.data);

          const charts = parsedData.charts;
          const settings = parsedData.settings;

          const defaultChart =
            charts &&
            charts[settings.defaultChart] &&
            charts[settings.defaultChart].yearData &&
            charts[settings.defaultChart].yearData[settings.defaultYear];

          if (!defaultChart) return;

          return (
            <Col lg={4} className="mb-5">
              <ChartX
                ChartElement={Line}
                chartData={defaultChart}
                dataSet={defaultChart.data}
                chartType={defaultChart.type}
                settings={defaultChart.settings}
              />
              <span
                className="vis-card-title"
                onClick={() => {
                  history.push(`/charts/${category.id}/data/${chart.id}`);
                }}
              >
                {chart.title} ({defaultChart.year})
              </span>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

const mapStateToProps = ({
  metricsState: { metricss, isLoading },
  metricsCategoryState: { metricsCategorys },
  metricsGroupTypeState: { metricsGroupTypes },
  metricsGroupState: { metricsGroups },
}) => ({
  metricss,
  isLoading,
  metricsCategorys,
  metricsGroupTypes,
  metricsGroups,
});

export default connect(mapStateToProps, {
  fetchMetricss,
  fetchMetricsCategorys,
  fetchMetricsGroupTypes,
  fetchMetricsGroups,
})(ChartPage);
