import { SET_IS_LOADING, SET_OUTCOMES_DATA } from "./action-types";

export const initialState = { isLoading: false, outcomes: undefined };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_OUTCOMES_DATA:
      return { ...state, outcomes: action.payload };

    default:
      return state;
  }
};
