import React from "react";

import { Link } from "react-router-dom";

import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";

import Twitter from "../components/Twitter/Twitter";

const Footer = ({
  websiteInformations = [],
  quickLinkCategorys = [],
  documentCategorys = [],
  partners = [],
  documentGroups = [],
  documentCategoryIsLoading,
  documentGroupIsLoading,
  websiteInformationIsLoading,
  quickLinkCategoryIsLoading,
  partnerIsLoading,
}) => {
  const facebookLink = websiteInformations.find(
    (item) => item.slug === "facebook"
  );
  const twitterLink = websiteInformations.find(
    (item) => item.slug === "twitter"
  );
  const twitterProfileLink = websiteInformations.find(
    (item) => item.slug === "twitterprofile"
  );
  const instagramLink = websiteInformations.find(
    (item) => item.slug === "instagram"
  );
  const youtubeLink = websiteInformations.find(
    (item) => item.slug === "youtube"
  );

  const governmentDocuments = documentCategorys.find(
    (item) => item.title === "Government Documents"
  );

  return (
    <footer className="constant__padding">
      <div className="footer__items footer__items-1">
        {quickLinkCategoryIsLoading ? (
          <div className="loading" />
        ) : (
          quickLinkCategorys.map((category) => {
            return <Link to={`/links/${category.id}`}>{category.title}</Link>;
          })
        )}
      </div>

      <div className="footer__items footer__items-3">
        <div className="footer__title">Partner Details</div>
        <div className="footer__items-link">
          {partnerIsLoading ? (
            <div className="loading" />
          ) : (
            partners.map((partner) => {
              return (
                <Link
                  to={{
                    pathname: partner.url,
                  }}
                  target="_blank"
                >
                  {partner.title}
                </Link>
              );
            })
          )}
        </div>
      </div>
      <div className="footer__items footer__items-5">
        {websiteInformationIsLoading ? (
          <div className="loading" />
        ) : (
          twitterProfileLink && (
            <Twitter screenName={twitterProfileLink.description} />
          )
        )}
      </div>
      <div className="footer__items footer__items-4">
        <Link to="/" className="footer__logo">
          <img
            src={require("../assets/images/Light__logo.png").default}
            alt=""
          />
        </Link>
        {websiteInformationIsLoading ? (
          <div className="loading" />
        ) : (
          <div className="social__links">
            <span className="text">Find Us</span>
            {facebookLink && (
              <Link
                to={{
                  pathname: facebookLink.description,
                }}
                target="_blank"
              >
                <FaFacebookSquare />
              </Link>
            )}
            {twitterLink && (
              <Link
                to={{
                  pathname: twitterLink.description,
                }}
                target="_blank"
              >
                <FaTwitterSquare />
              </Link>
            )}
            {instagramLink && (
              <Link
                to={{
                  pathname: instagramLink.description,
                }}
                target="_blank"
              >
                <FaInstagram />
              </Link>
            )}
            {youtubeLink && (
              <Link
                to={{
                  pathname: youtubeLink.description,
                }}
                target="_blank"
              >
                <FaYoutube />
              </Link>
            )}
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
