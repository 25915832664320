import { SET_METRICS_GROUP_TYPES_DATA } from "./action-types";

export const initialState = {
  metricsGroupTypes: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_METRICS_GROUP_TYPES_DATA:
      return { ...state, metricsGroupTypes: action.payload };

    default:
      return state;
  }
};
