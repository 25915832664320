import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = ({ documentCategorys = [], isLoading }) => {
  const [windowWidth, setWindowWidth] = useState();

  function sizeGetter() {
    setWindowWidth((prev) => window.innerWidth);
  }

  useEffect(() => {
    sizeGetter();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", sizeGetter);

    return () => {
      // remove resize listener
      window.removeEventListener("resize", sizeGetter);
    };
  }, [windowWidth]);

  const [dots, setDots] = useState(false);
  return (
    <header className="">
      <nav className="nav-area">
        <input type="checkbox" id="check" />
        <div className="hamburger-menu-container">
          <div className="hamburger-menu">
            <div></div>
          </div>
        </div>
        <div className="overlay"></div>
        <div className="nav-nav padding">
          <div className="logo">
            <Link to="/">
              <img
                src={require("../assets/images/SaveLogo.png").default}
                alt=""
              />
            </Link>
          </div>
        </div>
        <div className="main__nav-container">
          <div className="nav__list-container">
            <div className="nav__list">
              <Link to="/" className="nav__list-link ">
                Home
              </Link>
              {/* <Link to="/about" className="nav__list-link ">
                About Project
              </Link> */}
              {/* here the code of dynamic navigation */}
              {windowWidth > 1190
                ? documentCategorys.slice(0, 4).map((category) => {
                    return (
                      <Link
                        to={`/documents/${category.id}`}
                        className="nav__list-link "
                      >
                        {category.title}
                      </Link>
                    );
                  })
                : documentCategorys.map((category) => {
                    return (
                      <Link
                        to={`/documents/${category.id}`}
                        className="nav__list-link "
                      >
                        {category.title}
                      </Link>
                    );
                  })}
              {isLoading && <div className="loader-sm" />}
              {documentCategorys.length > 3 && (
                <div
                  className="dot__navigation desktop__dot-navigation"
                  onClick={() => setDots(!dots)}
                >
                  <div className="dot__navigation-icon">
                    <i class="micro-1d-icon uil uil-ellipsis-v">
                      {dots && (
                        <div className="extra__navigation">
                          {documentCategorys
                            .slice(4, documentCategorys.length)
                            .map((category) => {
                              return (
                                <Link
                                  to={`/documents/${category.id}`}
                                  className="nav__list-link "
                                >
                                  {category.title}
                                </Link>
                              );
                            })}
                        </div>
                      )}
                    </i>
                  </div>
                </div>
              )}
              <button
                className="btn btn-logout"
                onClick={() => {
                  sessionStorage.removeItem("token");
                  sessionStorage.removeItem("persist:root");
                  window.location.href = "/login";
                }}
              >
                <i class="fas fa-sign-out-alt"></i>
                <span>Logout</span>
              </button>
            </div>
          </div>

          {/* <div className="action">
            <Link to="/" className="action-btn btn btn-logIn">
              Login
            </Link>
            <Link to="/" className="action-btn btn btn-SignUp">
              SignUp
            </Link>
          </div> */}
        </div>
      </nav>
    </header>
  );
};

export default Header;
