import React from "react";

import Research from "../components/Page/ResearchandStudy/Documents";
import QuickLinks from "../components/Page/QuickLinks";
import InfoPage from "../components/Page/InfoPage";
import About from "../components/Page/About";

import ChartPage from "../components/Page/Charts";
import ChartCategoryPage from "../components/Page/ChartCategory";
import Homepage from "../components/Page/Homepage/index";

import MonthlyHightlights from "../components/Page/View/MonthlyHightlights";
import Newsletter from "../components/Page/View/Newsletter";
import Notices from "../components/Page/View/Notices";
import ProjectUpdates from "../components/Page/View/ProjectUpdates";

import { Route, Switch } from "react-router-dom";

const Content = () => {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="aligned">
      <Switch>
        <Route path="/documents/:id" exact component={Research} />
        <Route path="/monthly-highlights" component={MonthlyHightlights} />
        <Route path="/newsletter" component={Newsletter} />
        <Route path="/notices" component={Notices} />
        <Route path="/project-updates" component={ProjectUpdates} />
        <Route path="/links/:id" exact component={QuickLinks} />
        <Route path="/info-page/" exact component={InfoPage} />
        <Route
          path="/charts/:categoryId/data/:chartId"
          exact
          component={ChartPage}
        />{" "}
        <Route path="/charts/:categoryId" exact component={ChartCategoryPage} />
        <Route path="/about" exact component={About} />
        <Route path="/" exact component={Homepage} />
      </Switch>
    </div>
  );
};

export default Content;
