import { Timeline } from "react-twitter-widgets";

import React from "react";

const Twitter = ({ screenName }) => {
  return (
    // Tweet (without options)
    <>
      {/* <Tweet tweetId="841418541026877441" /> */}
      {/* // Timeline (with options) */}
      <Timeline
        dataSource={{
          sourceType: "profile",
          screenName: screenName,
        }}
        options={{
          height: "100%",
        }}
      />
    </>
  );
};

export default Twitter;
