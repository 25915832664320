import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} sahayatri__slider-right`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <FaAngleRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} sahayatri__slider-left`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <FaAngleLeft />
    </div>
  );
}

const SliderItem = ({ url, title, description }) => {
  return (
    <div className="slider-container">
      <div className="sliderImageContainer">
        <img src={url} alt={title} />
      </div>
      <div className="content-box">{description && description}</div>
    </div>
  );
};
const Sliders = ({ homeImages = [], isLoading = false }) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 100,
    cssEase: "linear",
    initialSlide: 0,
    arrows: true,
    fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="slider sahayatri__slider">
      {isLoading ? (
        <div class="loader-sm" />
      ) : (
        <Slider {...settings}>
          {homeImages.map((slider) => (
            <SliderItem {...slider} key={slider.id} />
          ))}
        </Slider>
      )}
    </div>
  );
};

export default Sliders;
