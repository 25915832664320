import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Overlay from "../../Overlay/Overlay";
import Tabs from "./Tabs";
import Modal from "../../Model/Model";
import { fetchDocumentByCategory } from "../../../store/document/actions";
import { fetchDocumentGroups } from "../../../store/documentGroup/actions";

const Documents = ({
  match: {
    params: { id },
  },
  fetchDocumentByCategory,
  documents,
  fetchDocumentGroups,
  documentGroups,
  isLoading,
}) => {
  useEffect(() => {
    id && fetchDocumentByCategory({ id });
    fetchDocumentGroups({});
  }, [id]);

  const [getModal, setModal] = useState(false);

  function closeOverlay() {
    setModal(false);
  }

  if (isLoading) {
    return (
      <div className="constant__padding min_height">
        <div className="loader-sm" />
      </div>
    );
  }

  return (
    <div className="constant__padding min_height">
      <Overlay show={getModal} close={closeOverlay} />
      <Modal
        show={getModal}
        close={closeOverlay}
        heading="Viewing Document ########"
        body="File Information"
      />
      <Tabs
        onView={() => setModal((prev) => !prev)}
        documents={documents}
        documentGroups={documentGroups}
        categoryId={id}
      />
    </div>
  );
};

const mapStateToProps = ({
  appState: { me },
  documentState: { isLoading, documents },
  documentGroupState: { documentGroups },
}) => ({
  me,
  documents,
  documentGroups,
  isLoading,
});
export default connect(mapStateToProps, {
  fetchDocumentByCategory,
  fetchDocumentGroups,
})(Documents);
