import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";

import Login from "./components/Login/index";
import ForgotPassword from "./components/Forgot/";
import ResetPassword from "./components/Reset/";
import Register from "./components/Register";
import Layout from "./containers/Layout";
import VerifyMessage from "./components/Page/VerifyMessage";

const Router = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<>loading...</>}>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/register" exact component={Register} />
          <Route
            path="/verify/:uid/:token"
            exact
            component={VerifyMessage}
          ></Route>
          <Route
            path="/reset-password/:uid/:token"
            exact
            component={ResetPassword}
          ></Route>
          <Route
            render={(props) =>
              sessionStorage.getItem("token") ? (
                <Layout {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default Router;
