import React, { useEffect } from "react";
import { connect } from "react-redux";

import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";

import { fetchMe } from "../store/app/actions";

import { fetchQuickLinkCategorys } from "../store/quickLinkCategory/actions";
import { fetchPartners } from "../store/partner/actions";
import { fetchDocumentCategorys } from "../store/documentCategory/actions";
import { fetchDocumentGroups } from "../store/documentGroup/actions";
import { fetchWebsiteInformations } from "../store/websiteInformation/actions";

const Layout = ({
  me,
  fetchMe,
  fetchQuickLinkCategorys,
  fetchDocumentGroups,
  fetchPartners,
  fetchDocumentCategorys,
  fetchWebsiteInformations,
  websiteInformations,
  quickLinkCategorys,
  documentCategorys,
  partners,
  documentGroups,

  documentCategoryIsLoading,
  documentGroupIsLoading,
  websiteInformationIsLoading,
  quickLinkCategoryIsLoading,
  partnerIsLoading,
}) => {
  useEffect(() => {
    !me && fetchMe({});

    fetchDocumentCategorys({});
    fetchDocumentGroups({});
    fetchQuickLinkCategorys({});
    fetchPartners({});
    fetchWebsiteInformations({});
  }, []);

  return (
    <>
      <Header
        {...{
          documentCategorys,
        }}
        isLoading={documentCategoryIsLoading}
      />
      <Content />
      <Footer
        {...{
          websiteInformations,
          quickLinkCategorys,
          documentCategorys,
          partners,
          documentGroups,
          documentCategoryIsLoading,
          documentGroupIsLoading,
          websiteInformationIsLoading,
          quickLinkCategoryIsLoading,
          partnerIsLoading,
        }}
      />
    </>
  );
};

const mapStateToProps = ({
  appState: { me },
  quickLinkCategoryState: {
    isLoading: quickLinkCategoryIsLoading,
    quickLinkCategorys,
  },
  partnerState: { isLoading: partnerIsLoading, partners },
  documentCategoryState: {
    isLoading: documentCategoryIsLoading,
    documentCategorys,
  },
  documentGroupState: { isLoading: documentGroupIsLoading, documentGroups },
  websiteInformationState: {
    isLoading: websiteInformationIsLoading,
    websiteInformations,
  },
}) => ({
  me,
  documentGroups,
  quickLinkCategorys,
  partners,
  documentCategorys,
  websiteInformations,
  quickLinkCategoryIsLoading,
  partnerIsLoading,
  documentCategoryIsLoading,
  documentGroupIsLoading,
  websiteInformationIsLoading,
});

export default connect(mapStateToProps, {
  fetchMe,
  fetchQuickLinkCategorys,
  fetchPartners,
  fetchDocumentCategorys,
  fetchDocumentGroups,
  fetchWebsiteInformations,
})(Layout);
