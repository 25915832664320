import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ListItem from "../../List/ListItem";

import {
  fetchNewsletters,
  updateViewCount,
  updateDownloadCount,
} from "../../../store/newsletter/actions";

import Modal from "../../Model/Model";
import Overlay from "../../Overlay/Overlay";
import { useHistory } from "react-router-dom";

const ViewList = ({
  fetchNewsletters,
  newsletters,
  isLoading,
  updateViewCount,
  updateDownloadCount,
}) => {
  useEffect(() => {
    fetchNewsletters({});
  }, []);

  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  let history = useHistory();

  const [getModal, setModal] = useState(false);

  function closeOverlay() {
    setModal(false);
  }
  
  if (isLoading) {
    return (
      <div className="constant__padding min_height">
        <div className="loader-sm" />
      </div>
    );
  }

  return (
    <div className="view__container constant__padding min_height">
      <Overlay show={getModal} close={closeOverlay} />
      <span
        style={{
          fontSize: "2.5rem",
          marginBottom: "4rem",
          display: "inline-block",
        }}
      >
        Newsletter
      </span>
      <div className="list__container listView">
        {newsletters
          .filter((item) => item.is_active)
          .map((item, i) => {
            return (
              <ListItem
                key={i}
                {...item}
                i={i}
                item={item}
                className="listView"
                onClick={() => {
                  updateViewCount({ id: item.id });
                  history.push({
                    pathname: "/info-page",
                    state: { document: item, type: "Newsletter" },
                  });
                }}
                onClickDownload={async () => {
                  (await updateDownloadCount({ id: item.id })) &&
                    window.open(item.url);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ newsletterState: { newsletters, isLoading } }) => ({
  newsletters,
  isLoading,
});
export default connect(mapStateToProps, {
  fetchNewsletters,
  updateViewCount,
  updateDownloadCount,
})(ViewList);
