import React from "react";
import { useHistory } from "react-router-dom";

const ProjectUpdate = ({type, item, onView, setActiveDocument, isLoading }) => {
  let history = useHistory();

  const lists = item.map((item, i) => {
    return (
      <tr>
        <td className="counter">{i + 1}</td>
        <td
          className="title updated__title"
          onClick={() => {
            history.push({
              pathname: "/info-page",
              state: { document: item, type },
            });
          }}
        >
          {item.title}
        </td>
        {/* <td className="status">{item.status}</td> */}
      </tr>
    );
  });

  if (isLoading) {
    return <div className="loader-sm" />;
  }

  return (
    <table className="product__component">
      <thead>
        <tr>
          <th className="sn">S.N</th>
          <th>Name</th>
          {/* <th>Status</th> */}
          {/* <th>Action</th> */}
        </tr>
      </thead>
      <tbody>{lists}</tbody>
    </table>
  );
};

export default ProjectUpdate;
