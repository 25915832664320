import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ListItem from "../../List/ListItem";

import { useHistory } from "react-router-dom";

import {
  fetchProjectUpdates,
  updateViewCount,
  updateDownloadCount,
} from "../../../store/projectUpdate/actions";

import Modal from "../../Model/Model";
import Overlay from "../../Overlay/Overlay";

const ViewList = ({
  fetchProjectUpdates,
  projectUpdates,
  isLoading,
  updateViewCount,
  updateDownloadCount,
}) => {
  let history = useHistory();
  useEffect(() => {
    fetchProjectUpdates({});
  }, []);

  const [getModal, setModal] = useState(false);

  const [activeDocument, setActiveDocument] = useState();

  function closeOverlay() {
    setModal(false);
  }
  if (isLoading) {
    return (
      <div className="constant__padding min_height">
        <div className="loader-sm" />
      </div>
    );
  }
  return (
    <div className="view__container constant__padding min_height">
      <Overlay show={getModal} close={closeOverlay} />
      {activeDocument && (
        <Modal
          show={getModal}
          buttonAction={closeOverlay}
          heading={activeDocument.title}
          otherButtons={
            activeDocument &&
            activeDocument.is_downloadable && (
              <>
                <div>
                  <a
                    href={activeDocument.url}
                    download
                    className="btn-modal"
                    target="_blank"
                    onClick={closeOverlay}
                  >
                    Download
                  </a>
                </div>
              </>
            )
          }
        />
      )}
      <span
        style={{
          fontSize: "2.5rem",
          marginBottom: "4rem",
          display: "inline-block",
        }}
      >
        Project Updates
      </span>
      <div className="list__container listView">
        {projectUpdates
          .filter((item) => item.is_active)
          .map((item, i) => {
            return (
              <ListItem
                key={i}
                {...item}
                i={i}
                item={item}
                className="listView"
                onClick={() => {
                  updateViewCount({ id: item.id });
                  history.push({
                    pathname: "/info-page",
                    state: { document: item, type: "Project Update" },
                  });
                }}
                onClickDownload={async () => {
                  (await updateDownloadCount({ id: item.id })) &&
                    window.open(item.url);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  projectUpdateState: { projectUpdates, isLoading },
}) => ({
  projectUpdates,
  isLoading,
});
export default connect(mapStateToProps, {
  fetchProjectUpdates,
  updateViewCount,
  updateDownloadCount,
})(ViewList);
