import React from "react";

const Search = () => {
  return (
    <div class="search__form">
      <input id="search" type="text" placeholder="Search" />
      <label for="search">
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.625 22.625L17.5783 17.5693M20.375 10.8125C20.375 13.3486 19.3675 15.7809 17.5742 17.5742C15.7809 19.3675 13.3486 20.375 10.8125 20.375C8.27637 20.375 5.84411 19.3675 4.05079 17.5742C2.25748 15.7809 1.25 13.3486 1.25 10.8125C1.25 8.27637 2.25748 5.84411 4.05079 4.05079C5.84411 2.25748 8.27637 1.25 10.8125 1.25C13.3486 1.25 15.7809 2.25748 17.5742 4.05079C19.3675 5.84411 20.375 8.27637 20.375 10.8125Z"
            stroke=""
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </label>
    </div>
  );
};

export default Search;
