const AccordianData1 = [
  {
    title: "Newsletter",
    content: [
      {
        id: "1",
        title: "News-24",
      },
      {
        id: "2",
        title: "News-24",
      },
      {
        id: "3",
        title: "News-24",
      },
      {
        id: "4",
        title: "News-24",
      },
      {
        id: "5",
        title: "News-24",
      },
      {
        id: "6",
        title: "News-24",
      },
      {
        id: "7",
        title: "News-24",
      },
    ],
  },

  {
    title: "Project Update",
    content: [
      {
        id: "1",
        title: "News-24",
        status: "ongoing",
      },
      {
        id: "2",
        title: "News-24",
        status: "ongoing",
      },
      {
        id: "3",
        title: "News-24",
        status: "ongoing",
      },
      {
        id: "4",
        title: "News-24",
        status: "ongoing",
      },
      {
        id: "5",
        title: "News-24",
        status: "ongoing",
      },
      {
        id: "6",
        title: "News-24",
        status: "ongoing",
      },
      {
        id: "7",
        title: "News-24",
        status: "ongoing",
      },
    ],
  },
  {
    title: "Monthly Highlights",
    content: [
      {
        id: "1",
        title: "News-24",
      },
      {
        id: "2",
        title: "News-24",
      },
      {
        id: "3",
        title: "News-24",
      },
      {
        id: "4",
        title: "News-24",
      },
      {
        id: "5",
        title: "News-24",
      },
      {
        id: "6",
        title: "News-24",
      },
      {
        id: "7",
        title: "News-24",
      },
    ],
  },
  {
    title: "Notice",
    content: [
      {
        id: "1",
        title: "News-24",
        date: {
          month: "oct",
          day: "11",
        },
      },
      {
        id: "2",
        title: "News-24",
        date: {
          month: "oct",
          day: "11",
        },
      },
      {
        id: "3",
        title: "News-24",
        date: {
          month: "oct",
          day: "11",
        },
      },
      {
        id: "4",
        title: "News-24",
        date: {
          month: "oct",
          day: "11",
        },
      },
      {
        id: "5",
        title: "News-24",
        date: {
          month: "oct",
          day: "11",
        },
      },
      {
        id: "6",
        title: "News-24",
        date: {
          month: "oct",
          day: "11",
        },
      },
      {
        id: "7",
        title: "News-24",
        date: {
          month: "oct",
          day: "11",
        },
      },
    ],
  },
];

export default AccordianData1;
