import { combineReducers } from "redux";

import appState from "./app/reducers";

import documentState from "./document/reducers";
import documentCategoryState from "./documentCategory/reducers";
import documentGroupState from "./documentGroup/reducers";
import homeImageState from "./homeImage/reducers";
import monthlyHighlightState from "./monthlyHighlight/reducers";
import newsletterState from "./newsletter/reducers";
import noticeState from "./notice/reducers";
import partnerState from "./partner/reducers";
import projectUpdateState from "./projectUpdate/reducers";
import permissionState from "./permission/reducers";
import quickLinkState from "./quickLink/reducers";
import quickLinkCategoryState from "./quickLinkCategory/reducers";
import websiteInformationState from "./websiteInformation/reducers";

import metricsState from "./metrics/reducers";

import themeState from "./theme/reducers";
import indicatorState from "./indicator/reducers";
import outcomeState from "./outcome/reducers";

import metricsCategoryState from "./metricsCategory/reducers";
import metricsGroupTypeState from "./metricsGroupType/reducers";
import metricsGroupState from "./metricsGroup/reducers";
import accessGroupState from "./accessGroup/reducers";

const rootReducer = combineReducers({
  appState,
  documentState,
  documentCategoryState,
  documentGroupState,
  homeImageState,
  monthlyHighlightState,
  newsletterState,
  noticeState,
  partnerState,
  projectUpdateState,
  permissionState,
  quickLinkState,
  quickLinkCategoryState,
  websiteInformationState,
  themeState,
  indicatorState,
  outcomeState,
  metricsState,
  metricsCategoryState,
  accessGroupState,
  metricsGroupTypeState,
  metricsGroupState,
});

export default rootReducer;
