import React from "react";
import App from "./App";
import ReactDOM from "react-dom";

import annotationPlugin from "chartjs-plugin-annotation";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

Chart.register(annotationPlugin);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
