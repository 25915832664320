import React from "react";
import { connect } from "react-redux";
import { forgotPassword, resetNotification } from "../../store/app/actions";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Notification from "../../components/Notification/index";

const LoginForm = ({
  forgotPassword,
  resetNotification,
  notification,
  isLoading,
}) => {
  const initialValues = { email: "" };

  const validationSchema = Yup.object({
    email: Yup.string().required().label("Email"),
  });

  const onSubmit = async (values, { resetForm }) =>
    (await forgotPassword({ values })) && resetForm();

  return (
    <>
      <div className="red__border">
        <div className="form__container constant__padding">
          <div className="image__content">
            <div className="welcome__text heading__primary">Welcome</div>
            <div className="image">
              <img
                src={require("../../assets/images/saveTheChildren.png").default}
                alt=""
              />
            </div>
          </div>
          <div className="form__content-sign">
            <h2 className="heading__primary">Forgot Password</h2>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              validateOnMount={false}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize
            >
              {({ values, errors, handleChange }) => (
                <Form className="form">
                  <div className="control-group">
                    <div className="form--control">
                      <div className="form__group">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="far"
                          data-icon="user"
                          class="svg-inline--fa fa-user fa-w-14 "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          color="black"
                        >
                          <path
                            fill="#f8f8f8"
                            d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"
                          ></path>
                        </svg>
                        <label htmlFor="name">Email</label>
                      </div>
                      <input
                        type="text"
                        id="name"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors && errors.email && <>{errors.email}</>}
                    </div>
                  </div>

                  <div className="form-actions">
                    <div className="form-actions">
                      <button
                        className="btn btn--transparent"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "Submitting" : "Submit"}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="links__cr">
              <Link to="/login">Login?</Link>
              <p>
                Don't have an account? <Link to="/register">Create</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {!!(notification && notification.length) && (
        <Notification
          data={notification[0]}
          resetNotification={resetNotification}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ appState: { notification, isLoading } }) => ({
  notification,
  isLoading,
});
export default connect(mapStateToProps, {
  forgotPassword,
  resetNotification,
})(LoginForm);
