import React from "react";
import { useHistory } from "react-router-dom";

const Common = ({
  type = "",
  item,
  classSN,
  onView,
  setActiveDocument,
  isLoading,
}) => {
  let history = useHistory();

  const lists = item
    .filter((item) => item.is_active)
    .slice(0, 5)
    .map((item, i) => {
      return (
        <div className="common__component">
          <p className={`counter ${classSN}`}>
            {classSN !== "date" ? (
              i + 1
            ) : (
              <div className="calender">
                {/* <span>{item.date.month}</span> */}
                {/* <span>{item.date.day}</span> */}
                {/* <span>Baisakh</span> */}
                {/* <span>08</span> */}
              </div>
            )}
          </p>
          <div
            className="title updated__title"
            onClick={() => {
              // onView();
              // setActiveDocument(item);
              history.push({
                pathname: "/info-page",
                state: { document: item, type: type },
              });
            }}
          >
            {item.title}
          </div>
          {/* <button
            className="accordian__button"
            onClick={() => {
              onView();
              setActiveDocument(item);
            }}
          >
            View
          </button> */}
        </div>
      );
    });

  if (isLoading) {
    return <div className="loader-sm" />;
  }

  return <>{lists}</>;
};

export default Common;
