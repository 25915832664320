import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import Router from "./Router";
import { Provider } from "react-redux";
import { initializeStore } from "./store";
import "./scss/main.scss";

const store = initializeStore();

function App() {
  document.title = "SAHAYATRA II";
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
