import { SET_IS_LOADING, SET_QUICK_LINKS_DATA } from "./action-types";

export const initialState = { isLoading: false, quickLinks: undefined };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_QUICK_LINKS_DATA:
      return { ...state, quickLinks: action.payload };

    default:
      return state;
  }
};
