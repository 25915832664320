import { SET_METRICS_GROUPS_DATA } from "./action-types";

export const initialState = {
  metricsGroups: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_METRICS_GROUPS_DATA:
      return { ...state, metricsGroups: action.payload };

    default:
      return state;
  }
};
