import { SET_IS_LOADING, SET_METRICS_CATEGORYS_DATA } from "./action-types";

import { setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";

export const setIsLoading = (payload) => ({ type: SET_IS_LOADING, payload });

export const setMetricsCategorysData = (payload) => ({
  type: SET_METRICS_CATEGORYS_DATA,
  payload,
});

export const fetchMetricsCategorys =
  ({}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({ dispatch }).get(
        url.metricsCategory
      );
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setMetricsCategorysData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
