import {
  SET_IS_LOADING,
  SET_METRICSS_DATA,
  SET_METRICS_DATA,
} from "./action-types";

export const initialState = {
  isLoading: false,
  metricss: [],
  metrics: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_METRICSS_DATA:
      return { ...state, metricss: action.payload };
    case SET_METRICS_DATA:
      return { ...state, metrics: action.payload };

    default:
      return state;
  }
};
