import React, { useState } from "react";

import Modal from "../Model/Model";

import { configuration } from "../../configuration/env";

const ListItem = ({
  title,
  url,
  i,
  onClick,
  className,
  is_downloadable = true,
  onClickDownload,item
}) => {
  return (
    <div className={`list__item`}>
      {className && <div style={{ fontWeight: 500 }}>{`${i + 1}`}</div>}
      <div className="fileName">{title}</div>
      <div className="see__icon" onClick={onClick}>
        <svg viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9995 15C17.2087 15 18.9995 13.2091 18.9995 11C18.9995 8.79086 17.2087 7 14.9995 7C12.7904 7 10.9995 8.79086 10.9995 11C10.9995 13.2091 12.7904 15 14.9995 15Z"
            fill="#DE4338"
          />
          <path
            d="M29.9396 10.66C28.7634 7.61765 26.7216 4.98662 24.0664 3.09209C21.4112 1.19756 18.2591 0.122573 14.9996 0C11.7401 0.122573 8.58796 1.19756 5.93278 3.09209C3.27759 4.98662 1.23574 7.61765 0.0595746 10.66C-0.0198582 10.8797 -0.0198582 11.1203 0.0595746 11.34C1.23574 14.3824 3.27759 17.0134 5.93278 18.9079C8.58796 20.8024 11.7401 21.8774 14.9996 22C18.2591 21.8774 21.4112 20.8024 24.0664 18.9079C26.7216 17.0134 28.7634 14.3824 29.9396 11.34C30.019 11.1203 30.019 10.8797 29.9396 10.66ZM14.9996 17.5C13.714 17.5 12.4573 17.1188 11.3884 16.4046C10.3194 15.6903 9.48633 14.6752 8.99436 13.4874C8.50239 12.2997 8.37367 10.9928 8.62447 9.73191C8.87527 8.47104 9.49434 7.31285 10.4034 6.40381C11.3124 5.49476 12.4706 4.8757 13.7315 4.6249C14.9924 4.37409 16.2993 4.50281 17.487 4.99478C18.6747 5.48675 19.6899 6.31987 20.4041 7.38879C21.1184 8.45771 21.4996 9.71442 21.4996 11C21.4969 12.7231 20.8113 14.3749 19.5928 15.5933C18.3744 16.8117 16.7227 17.4974 14.9996 17.5Z"
            fill="#DE4338"
          />
        </svg>
      </div>
      {!!is_downloadable && onClickDownload && (
        <a  download >
          <button  onClick={onClickDownload}>
            <svg
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 0C12.5255 0.00775972 10.1353 0.900253 8.26125 2.51625C6.825 3.75375 5.78437 5.36625 5.51625 6.98438C2.37375 7.67812 0 10.4156 0 13.7212C0 17.5613 3.2025 20.625 7.08938 20.625H14.0625V10.3125C14.0625 10.0639 14.1613 9.8254 14.3371 9.64959C14.5129 9.47377 14.7514 9.375 15 9.375C15.2486 9.375 15.4871 9.47377 15.6629 9.64959C15.8387 9.8254 15.9375 10.0639 15.9375 10.3125V20.625H23.79C27.1912 20.625 30 17.9438 30 14.5744C30 11.5069 27.6713 9.0075 24.6863 8.58562C24.2306 3.74813 20.0438 0 15 0ZM14.3363 29.7262C14.4233 29.8136 14.5268 29.8828 14.6407 29.9301C14.7546 29.9773 14.8767 30.0017 15 30.0017C15.1233 30.0017 15.2454 29.9773 15.3593 29.9301C15.4732 29.8828 15.5767 29.8136 15.6637 29.7262L21.2887 24.1012C21.4648 23.9252 21.5637 23.6865 21.5637 23.4375C21.5637 23.1885 21.4648 22.9498 21.2887 22.7738C21.1127 22.5977 20.874 22.4988 20.625 22.4988C20.376 22.4988 20.1373 22.5977 19.9613 22.7738L15.9375 26.7994V20.625H14.0625V26.7994L10.0387 22.7738C9.86271 22.5977 9.62395 22.4988 9.375 22.4988C9.12605 22.4988 8.88729 22.5977 8.71125 22.7738C8.53521 22.9498 8.43632 23.1885 8.43632 23.4375C8.43632 23.6865 8.53521 23.9252 8.71125 24.1012L14.3363 29.7262Z"
                fill="#DE4338"
              />
            </svg>
          </button>
        </a>
      )}
    </div>
  );
};

export default ListItem;
