import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Common from "./Common";
import ProjectUpdate from "./ProjectUpdate";
import AccordianData1 from "../../DummyData/AccordianData-1";

const Accordion = ({
  items,
  onView,
  setActiveDocument,

  newsletters = [],
  notices = [],
  projectUpdates = [],
  monthlyHighlights = [],

  newsletterIsLoading,
  noticeIsLoading,
  projectUpdateIsLoading,
  monthlyHighlightIsLoading,
}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  let elementDetail = useRef([]);

  useEffect(() => {
    setActiveIndex(0);
  }, []);

  const onTitleClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const item = items.map((item, i) => {
    const active = activeIndex === i ? "ACTIVE" : "";

    return (
      <div key={i} className={`accordian__block`}>
        <div
          className={`title`}
          style={{ pointerEvents: "auto" }}
          onClick={() => onTitleClick(i)}
        >
          <p>{item.title}</p>
          <span
            class="transform-rotate-icon uil uil-angle-down"
            style={active ? { transform: "rotate(-180deg)" } : {}}
          ></span>
        </div>
        <div
          className={`content`}
          ref={(ref) => {
            elementDetail.current[i] = ref;
          }}
          style={
            active
              ? { height: elementDetail.current[i].scrollHeight + "px" }
              : { height: "0" }
          }
        >
          {item.title == "Newsletter" && (
            <>
              <Common
                type="Newsletter"
                isLoading={newsletterIsLoading}
                item={
                  newsletters && newsletters.filter((item) => item.is_active)
                }
                classSN=""
                onView={onView}
                setActiveDocument={setActiveDocument}
              />
              {newsletters.filter((item) => item.is_active).length ? (
                <Link to="/newsletter" className="view">
                  View All
                </Link>
              ) : (
                <p className="no__content">No Data To View</p>
              )}
            </>
          )}
          {item.title == "Project Update" && (
            <>
              <ProjectUpdate
                type="Project Update"
                isLoading={projectUpdateIsLoading}
                item={
                  projectUpdates &&
                  projectUpdates.filter((item) => item.is_active)
                }
                onView={onView}
                setActiveDocument={setActiveDocument}
              />
              {projectUpdates.filter((item) => item.is_active).length ? (
                <Link to="/project-updates" className="view">
                  View All
                </Link>
              ) : (
                <p className="no__content">No Data To View</p>
              )}
            </>
          )}
          {item.title == "Monthly Highlights" && (
            <>
              <Common
                type="Monthly Highlights"
                isLoading={monthlyHighlightIsLoading}
                item={
                  monthlyHighlights &&
                  monthlyHighlights.filter((item) => item.is_active)
                }
                onView={onView}
                setActiveDocument={setActiveDocument}
              />
              {monthlyHighlights.filter((item) => item.is_active).length ? (
                <Link to="/monthly-highlights" className="view">
                  View All
                </Link>
              ) : (
                <p className="no__content">No Data To View</p>
              )}
            </>
          )}
          {item.title == "Notice" && (
            <>
              <Common
                type="Notice"
                isLoading={noticeIsLoading}
                item={notices && notices.filter((item) => item.is_active)}
                classSN="date"
                onView={onView}
                setActiveDocument={setActiveDocument}
              />
              {notices.filter((item) => item.is_active).length ? (
                <Link to="/notices" className="view">
                  View All
                </Link>
              ) : (
                <p className="no__content">No Data To View</p>
              )}
            </>
          )}
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="accordian__custom">{item}</div>
    </>
  );
};

export default Accordion;
