import React, { useState, useEffect } from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import { Chart } from "chart.js";

import Modal from "../Model/Model";
import Overlay from "../Overlay/Overlay";

const MChart = ({
  dataSet = [],
  settings,

  width = "400",
  height = "300",

  chartData,
  chartType = "Bar",

  showViewTable = true,
}) => {
  const [headers, setHeaders] = useState([]);
  const [rowHeaders, setRowHeaders] = useState([]);
  const [annotationDraw, setAnnotationDraw] = useState();

  const [holdings, setHoldings] = useState([]);
  const [data, setData] = useState([]);
  const [colors, setColors] = useState([]);

  const [showNote, setShowNote] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [customLabel, setCustomLabel] = useState([]);

  const closeOverlay = () => {
    setShowNote(false);
    setShowTable(false);
  };

  useEffect(() => {
    if (dataSet.length && dataSet[0] && dataSet[1]) {
      let header = dataSet[0];
      let body = dataSet.slice(2, dataSet.length);

      setHeaders(header.filter((_, i) => i));

      body.map((row, rowIndex) => {
        row.map((col, colIndex) => {
          !colIndex && setRowHeaders((prev) => [...prev, col[0]]);
        });
      });
    }
  }, []);

  useEffect(() => {
    if (dataSet.length && rowHeaders.length) {
      let body = dataSet.slice(2, dataSet.length);
      let subHeaders = dataSet[1];

      const filteredSubHeaders = subHeaders.filter((_, index) => index);

      body.map((row, rowIndex) => {
        const filteredRow = row.filter((_, index) => index);

        let holding = {};

        filteredRow.map((row, rowIndex) => {
          filteredSubHeaders[rowIndex].map((subCol, subColIndex) => {
            holding[subColIndex] = [
              ...(holding[subColIndex] || []),
              filteredRow[rowIndex][subColIndex],
            ];
          });
        });
        Object.values(holding).map((data, dataIndex) => {
          setHoldings((prev) => [
            ...prev,
            {
              label: rowHeaders[rowIndex],
              data: data,
              backgroundColor: settings.rowColors[rowIndex],
            },
          ]);
        });
      });
    }
  }, [rowHeaders]);

  useEffect(() => {
    let subHeaders = dataSet[1];

    const filteredSubHeaders = subHeaders.filter((_, index) => index);

    setAnnotationDraw(
      rowHeaders.map((item, index) => {
        let divider = "";

        filteredSubHeaders[index] &&
          filteredSubHeaders[index].map((item) => {
            divider += `${item}    `;
          });

        return {
          type: "line",
          scaleID: "x",
          borderWidth: 2,
          borderColor:
            filteredSubHeaders[index] && filteredSubHeaders[index].length > 1
              ? "black"
              : "white",
          value: index,
          label: {
            yAdjust: -80,
            // yValue: 520,
            content:
              filteredSubHeaders[index] && filteredSubHeaders[index].length > 1
                ? divider
                : "",
            enabled: true,
            backgroundColor: "transparent",
            color: "#000",
          },
        };
      })
    );
  }, [rowHeaders, dataSet]);

  useEffect(() => {
    if (holdings.length) {
      const even = holdings.filter((_, index) => index % 2 === 0);
      const odd = holdings.filter((_, index) => index % 2 !== 0);
      let arr = [];
      // For Setting custom label
      holdings.map((ele) => {
        if (!arr.some((el) => el.label === ele.label)) {
          arr.push(ele);
          setCustomLabel(arr);
        }
      });
      // for setting chart data
      if (holdings && holdings[0].data && holdings[0].data.length < 2) {
        even.map((data) => {
          setData((prev) => [...prev, data]);
          setColors((prev) => [
            ...prev,
            { label: data.label, backgroundColor: data.backgroundColor },
          ]);
        });
        odd.map((data) => {
          setData((prev) => [...prev, data]);
        });
      } else {
        holdings.map((data) => {
          setData((prev) => [...prev, data]);
        });
      }

      // if (!arr.some((el) => el.label === ele.label)) {
      //   arr.push(ele);
      //   setCustomLabel(arr);
      // }
      //
    }

    //
  }, [holdings]);

  // const [] = useState([]);

  // useEffect(() => {
  //   data.map((data) => {

  //     const setLabelData = ;
  //
  //     setColors((prev) => [
  //       ...prev,
  //       {
  //         label: data.label,
  //         backgroundColor: data.backgroundColor,
  //       },
  //     ]);
  //   });
  // });

  //

  if (!chartData) return <></>;

  const headerData = chartData.data[0];
  const subHeaders = chartData.data[1];
  const body = chartData.data.slice(2, chartData.length);
  const useSubHeaders = chartData.settings.useSubHeaders;
  const cData = chartData.data;

  const barChartOptions = {
    responsive: false,
    maintainAspectRatio: true,
    aspectRatio: 2,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
            borderDash: [3, 3],
            zeroLineColor: "blue",
          },
          categoryPercentage: 4,
          barPercentage: 5,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
      yAxes: [
        {
          display: false,
          gridLines: {
            display: false,
            zeroLineColor: "transparent",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      annotation: {
        annotations: annotationDraw,
      },

      title: {
        display: true,
        text: chartData ? chartData.title : "",
      },
      legend: {
        display: false,
      },
    },
  };
  const LineChartOptions = {
    responsive: false,
    maintainAspectRatio: true,
    aspectRatio: 2,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
            borderDash: [3, 3],
            zeroLineColor: "blue",
          },
          categoryPercentage: 4,
          barPercentage: 5,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
      yAxes: [
        {
          display: false,
          gridLines: {
            display: false,
            zeroLineColor: "transparent",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      title: {
        display: true,
        text: chartData ? chartData.title : "",
      },
      legend: {
        display: false,
      },
    },
  };

  const getElement = () => {
    switch (chartType) {
      case "Line":
        return (
          <Line
            width={width}
            height={height}
            data={{
              labels: headers,
              datasets: data,
            }}
            options={LineChartOptions}
          />
        );
      case "Pie":
        const label = [];
        const datas = [];
        const backgroundColor = [];

        data.map((item) => {
          item.label && label.push(item.label);
          item.data && item.data.length && datas.push(item.data[0]);
          item.backgroundColor && backgroundColor.push(item.backgroundColor);
        });

        const optionPie = {
          layout: {
            padding: {
              bottom: 15,
              // top: 50,
            },
          },
          tooltips: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          // aspectRatio: 1.2,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        };

        return (
          <Pie
            width={width}
            height={height}
            options={optionPie}
            className="pie__chart"
            data={{
              labels: label,
              datasets: [
                {
                  label: "",
                  data: datas,
                  backgroundColor,
                },
              ],
            }}
          />
        );
      default:
        return (
          <Bar
            width={width}
            height={height}
            data={{
              labels: headers,
              datasets: data,
            }}
            options={barChartOptions}
          />
        );
    }
  };

  return (
    <>
      <Overlay show={showNote || showTable} close={closeOverlay} />
      <Modal
        show={showNote}
        buttonAction={closeOverlay}
        heading={`Note for ${chartData.title}`}
        body={<p>{chartData.note}</p>}
      />{" "}
      <Modal
        show={showTable}
        buttonAction={closeOverlay}
        heading={`Table for ${chartData.title}`}
        body={
          <div className="table__size" style={{ width: "80vw" }}>
            <table className="modal__table">
              <thead>
                <tr>
                  <th />
                  {headerData.map(
                    (col, colIndex) =>
                      !!colIndex && (
                        <th
                          colSpan={
                            useSubHeaders
                              ? Number(cData[1][colIndex].length)
                              : 1
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>{col}</span>
                          </div>
                        </th>
                      )
                  )}
                </tr>
                <tr>
                  <th />
                  {!!useSubHeaders &&
                    subHeaders.map(
                      (col, colIndex) =>
                        !!colIndex && (
                          <>
                            {col.map((subCol, subColIndex) => (
                              <th>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>{subCol}</span>
                                </div>
                              </th>
                            ))}
                          </>
                        )
                    )}
                </tr>
              </thead>
              <tbody>
                {body.map((row, rowIndex) => {
                  return (
                    <tr>
                      {row.map((col, colIndex) => {
                        return (
                          <>
                            {col.map((subCol, subColIndex) => (
                              <td>
                                <div className="body-input">
                                  <span>{subCol}</span>
                                </div>
                              </td>
                            ))}
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        }
      />
      <div className="chart__container">{getElement()}</div>
      <div style={{ display: "flex", width: "100%", marginBottom: "1rem" }}>
        {customLabel.map((color) => {
          //
          return (
            <div style={{ display: "flex", marginRight: "1rem" }}>
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: color.backgroundColor,
                  marginRight: ".5rem",
                }}
              ></div>
              {color.label}
            </div>
          );
        })}
      </div>
      {chartData.note && (
        <button
          className="btn-modal-sm"
          onClick={() => {
            setShowNote(true);
          }}
        >
          View Note
        </button>
      )}{" "}
      {!!showViewTable && (
        <button
          className="btn-modal-sm"
          onClick={() => {
            setShowTable(true);
          }}
        >
          View Table
        </button>
      )}
    </>
  );
};

export default MChart;
