import {
  SET_IS_LOADING,
  SET_METRICSS_DATA,
  SET_METRICS_DATA,
} from "./action-types";

import { setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";

export const setIsLoading = (payload) => ({ type: SET_IS_LOADING, payload });

export const setMetricsData = (payload) => ({
  type: SET_METRICS_DATA,
  payload,
});

export const setMetricssData = (payload) => ({
  type: SET_METRICSS_DATA,
  payload,
});

export const fetchMetrics =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({ dispatch }).get(
        url.metrics + `${id}/`
      );
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setMetricsData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const fetchMetricss =
  ({}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({ dispatch }).get(
        url.metrics + `?limit=1000`
      );
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setMetricssData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
