import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import MultipleSlider from "./Slider/MultipleSlider";

import Modal from "../../Model/Model";
import Overlay from "../../Overlay/Overlay";

import Slider from "./Slider/Slider";

import AccordianData1 from "../../../DummyData/AccordianData-1";

import Accordion from "../../Accordian/Accordian";

import { fetchHomeImages } from "../../../store/homeImage/actions";
import { fetchNewsletters } from "../../../store/newsletter/actions";
import { fetchMetricss } from "../../../store/metrics/actions";
import { fetchNotices } from "../../../store/notice/actions";
import { fetchProjectUpdates } from "../../../store/projectUpdate/actions";
import { fetchMonthlyHighlights } from "../../../store/monthlyHighlight/actions";
import { fetchMetricsCategorys } from "../../../store/metricsCategory/actions";
import { Link } from "react-router-dom";

import About from "../About";

const Homepage = ({
  fetchHomeImages,
  fetchNewsletters,
  fetchNotices,
  fetchProjectUpdates,
  fetchMonthlyHighlights,
  fetchMetricsCategorys,
  me,
  homeImages = [],
  newsletters = [],
  notices = [],
  projectUpdates = [],
  monthlyHighlights = [],
  metricsCategorys = [],
  fetchMetricss,
  metricss = [],
  homeImageIsLoading,
  newsletterIsLoading,
  noticeIsLoading,
  projectUpdateIsLoading,
  monthlyHighlightIsLoading,
  metricsCategoryIsLoading,
  metricsIsLoading,
}) => {
  useEffect(() => {
    fetchHomeImages({});
    fetchNewsletters({});
    fetchNotices({});
    fetchProjectUpdates({});
    fetchMonthlyHighlights({});
    fetchMetricsCategorys({});
    fetchMetricss({});
  }, []);

  const [getModal, setModal] = useState(false);

  const [activeDocument, setActiveDocument] = useState();

  function closeOverlay() {
    setModal(false);
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="homepage constant__padding min_height" id="homepage">
      <Overlay show={getModal} close={closeOverlay} />
      {activeDocument && (
        <Modal
          show={getModal}
          buttonAction={closeOverlay}
          heading={activeDocument.title}
          body={<p style={{ margin: "2rem auto" }}>{activeDocument.content}</p>}
          otherButtons={
            activeDocument && activeDocument.is_downloadable && (
              <>
                <div>
                  <a
                    href={activeDocument.url}
                    download
                    className="btn-modal"
                    target="_blank"
                    onClick={closeOverlay}
                  >
                    Download
                  </a>
                </div>
              </>
            )
          }
        />
      )}
      <div className="heading-h seperate__head" style={{ fontSize: "2.4rem" }}>
        SAHAYATRA <span>KNOWLEDGE MANAGEMENT PORTAL</span>
      </div>
      <div className="first__section">
        <Slider
          homeImages={homeImages && homeImages.filter((item) => item.is_active)}
          isLoading={homeImageIsLoading}
        />
        <div className="accordian-section">
          <Accordion
            items={AccordianData1}
            onView={() => setModal((prev) => !prev)}
            setActiveDocument={setActiveDocument}
            {...{
              newsletters,
              notices,
              projectUpdates,
              monthlyHighlights,
              newsletterIsLoading,
              noticeIsLoading,
              projectUpdateIsLoading,
              monthlyHighlightIsLoading,
            }}
          />
        </div>
      </div>
      <div className="about-content">
        <div className="section__heading constant__margin-top">About</div>
        <About></About>
        <Link to="/about" className="view">
          View More
        </Link>
      </div>

      {metricsCategoryIsLoading ? (
        <div className="loading" />
      ) : (
        metricsCategorys.map((category) => {
          return (
            <>
              <div className="section__heading constant__margin-top">
                {category.title}
              </div>
              {metricsIsLoading ? (
                <div className="loading" />
              ) : (
                <MultipleSlider
                  category={category}
                  charts={metricss.filter(
                    (item) => item.metrics_category === category.id
                  )}
                />
              )}
            </>
          );
        })
      )}
    </div>
  );
};

const mapStateToProps = ({
  appState: { appIsLoading, me },
  homeImageState: { isLoading: homeImageIsLoading, homeImages },
  newsletterState: { isLoading: newsletterIsLoading, newsletters },
  noticeState: { isLoading: noticeIsLoading, notices },
  projectUpdateState: { isLoading: projectUpdateIsLoading, projectUpdates },
  monthlyHighlightState: {
    isLoading: monthlyHighlightIsLoading,
    monthlyHighlights,
  },
  metricsCategoryState: {
    isLoading: metricsCategoryIsLoading,
    metricsCategorys,
  },
  metricsState: { isLoading: metricsIsLoading, metricss },
}) => ({
  me,
  homeImages,
  newsletters,
  notices,
  projectUpdates,
  monthlyHighlights,
  metricsCategorys,
  metricss,
  appIsLoading,
  homeImageIsLoading,
  newsletterIsLoading,
  noticeIsLoading,
  projectUpdateIsLoading,
  monthlyHighlightIsLoading,
  metricsCategoryIsLoading,
  metricsIsLoading,
});
export default connect(mapStateToProps, {
  fetchHomeImages,
  fetchNewsletters,
  fetchNotices,
  fetchProjectUpdates,
  fetchMonthlyHighlights,
  fetchMetricsCategorys,
  fetchMetricss,
})(Homepage);
