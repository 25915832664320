import React, { useEffect } from "react";

const Model = ({
  show,
  buttonAction,
  image,
  heading,
  body,
  buttonName,
  otherButtons,
}) => {
  function disableScroll() {
    window.addEventListener("DOMMouseScroll", (e) => e.preventDefault(), false); // older FF
  }

  // call this to Enable
  function enableScroll() {
    window.removeEventListener(
      "DOMMouseScroll",
      (e) => e.preventDefault(),
      false
    );
  }

  useEffect(() => {
    show ? disableScroll() : enableScroll();
  }, [show]);

  return (
    <div className={`modal-sahayatra ${show ? "show" : ""}`}>
      {image}
      <div className="modal-sahayatra__text">
        <h4 className="modal-sahayatra__text-heading">{heading}</h4>
        <div className="modal-sahayatra__text modal-sahayatra__text-description">
          {body}
        </div>
        {buttonName && buttonAction && (
          <button className="btn-modal" onClick={buttonAction}>
            {buttonName}
          </button>
        )}
        {otherButtons}
      </div>
    </div>
  );
};

export default Model;
