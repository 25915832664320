import { SET_IS_LOADING, SET_ACCESS_GROUPS_DATA } from "./action-types";

import { setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";

export const setIsLoading = (payload) => ({ type: SET_IS_LOADING, payload });

export const setAccessGroupsData = (payload) => ({
  type: SET_ACCESS_GROUPS_DATA,
  payload,
});

export const fetchAccessGroups = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const { data, status } = await network({
      dispatch,
      requireToken: false,
    }).get(url.accessGroup);
    if (status === 200 || status > 200) {
      if (data) {
        dispatch(setAccessGroupsData(data));
        dispatch(setIsLoading(false));
        return true;
      }
    }
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};
