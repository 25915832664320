import { SET_IS_LOADING, SET_WEBSITE_INFORMATIONS_DATA } from "./action-types";

export const initialState = {
  isLoading: false,
  websiteInformations: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_WEBSITE_INFORMATIONS_DATA:
      return { ...state, websiteInformations: action.payload };

    default:
      return state;
  }
};
